'use strict';

define(['app'], function(app) {

  return function() {

    /**
     * Form Timer Object
     */
    var formTimerObj = {

      /**
       * Form node
       * @param element
       */
      init: function(element) {
        formTimerObj.fields = [];
        formTimerObj.timeSpent = null;
        formTimerObj.fieldName = null;
        formTimerObj.fieldId = null;
        formTimerObj.fieldClass = null;
        formTimerObj.leaveType = null;
        formTimerObj.timeSpent = null;
        formTimerObj.category = null;

        formTimerObj.listen(element);
      },

      /**
       * User enters form field
       * @param event
       */
      enterField: function(event) {
        formTimerObj.fields.push({
          'target': event.target.id,
          'startTime': new Date().getTime(),
          'timeSpent': null
        });
      },

      /**
       * User clicks something else
       */
      exitField: function() {
        var fieldsLength = formTimerObj.fields.length;
        var pTime = formTimerObj.fields[fieldsLength - 1];
        var timeInt = pTime.startTime;

        formTimerObj.timeSpent = new Date().getTime() - timeInt;
        formTimerObj.fields[fieldsLength - 1].timeSpent = formTimerObj.timeSpent;
        formTimerObj.pushData();
      },

      /**
       * Callback once user enters form
       * @param {object} event
       */
      listenEnter: function(event) {
        formTimerObj.timerValues(event);
        formTimerObj.enterField(event);
      },

      /**
       * User exit event callback
       * @param {object} event
       */
      listenExit: function(event) {
        formTimerObj.timerValues(event);
        formTimerObj.exitField(event);
      },

      /**
       * Set up event bindings
       * @param {object} element
       */
      listen: function(element) {
        if (element) {
          app.on('focus', formTimerObj.listenEnter, element, true);
          app.on('blur', formTimerObj.listenExit, element, true);
          app.on('change', formTimerObj.listenExit, element, true);
        }
      },

      /**
       * Pass event values to form timer
       * @param {object} e
       */
      timerValues: function(e) {
        if (e.target) {
          var categoryTestObj = app.element.closestI('.js-formTimer', e.target);
          var categoryTest = false;

          if (categoryTestObj) {
            categoryTest = categoryTestObj.element;
          }

          try {
            if (categoryTest) {
              formTimerObj.fieldName = e.target.name !== null || e.target.name !== '' ? e.target.name : null;
              formTimerObj.fieldId = e.target.id !== null || e.target.id !== '' ? e.target.id : null;
              //              formTimerObj.fieldClass = e.target.class !== null || e.target.class !== "" ? e.target.class : null;
              formTimerObj.leaveType = e.type;
              formTimerObj.category = categoryTest.id + ' form';
            }
          } catch (e) {}
        }
      },

      /**
       * Send values to google analytics
       */
      pushData: function() {
        //test variables and send data
        var timingCategory = formTimerObj.category;
        var timingVariable = formTimerObj.fieldName || formTimerObj.fieldId || formTimerObj.fieldClass;
        var timingLabel = formTimerObj.leaveType;
        var timingValue = formTimerObj.timeSpent;
        app.publish('tracking/fieldtiming', timingCategory, timingVariable, timingLabel, timingValue);
      }
    };

    return formTimerObj;
  };
});
